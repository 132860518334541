import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import numbro from "numbro";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import TenantDialogTitle from "../tenant-dialog-title";
import AdSpaceCard from "../card/ad-space-card-remove";
import { getSpacePriceConfigFromSpot, getAdSpacePriceFromConfig } from "../../utils/spots";
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    width: "740px",
    transition: "all 0.3s",
    padding: th.spacing(2),
    [th.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  divider: {
    width: "100%",
    borderBottom: `1px solid ${th.palette.black}`,
  },
  dividerSoft: {
    width: "100%",
    borderBottom: `1.5px solid ${th.palette.grayE5}`,
  },
}));

function SelectedAdSpaceModal({
  open,
  onClose,
  spots,
  spot,
  showAdSpaceCardAction = true,
  removeSelectedAdSpaces = () => {},
  adSpacesConsultation = [],
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const selectedSpot = spots.find((s) => s.slug === spot);
  const priceConfig = selectedSpot?.data?.price ?? getSpacePriceConfigFromSpot(spot);

  const handleClose = (e, r) => {
    if (r === "backdropClick" || r === "escapeKeyDown") {
      onClose();
    }
  };

  const handleShowTotalPrice = () => {
    const price = adSpacesConsultation.reduce((prev, curr) => {
      return (prev += curr.display_price);
    }, 0);

    return price ? numbro(price).formatCurrency() : numbro(0).formatCurrency();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={false}
      disableScrollLock
      PaperProps={{
        style: {
          maxWidth: "100%",
          padding: 0,
          borderRadius: "10px",
        },
      }}
    >
      <Box className={classes.modal}>
        <TenantDialogTitle onClose={onClose}>
          <Trans>Espacios publicitarios</Trans>
        </TenantDialogTitle>
        <Box mt={1} className={classes.divider} />
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2} p={1}>
          <Typography variant="subtitle1">
            <Trans>Items seleccionados</Trans>
            {`: ${adSpacesConsultation.length}`}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            {adSpacesConsultation[0]?.asset?.config?.show_adspace_price && (
              <>
                <Typography variant="subtitle1">
                  <Trans>Desde</Trans>
                  {`: ${handleShowTotalPrice()}/${i18n._(t`Día`)}`}
                </Typography>
                <Typography variant="body2" style={{ fontStyle: "italic" }}>
                  <Trans>*Los precios no incluyen IVA y otros impuestos</Trans>
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box width="100%" mt={1} p={1}>
          <Grid style={{ maxHeight: "300px", overflow: "auto" }} container spacing={4}>
            {adSpacesConsultation.map((ad) => (
              <Grid key={ad.id} style={{ width: "100%" }} item md={6} sm={12}>
                <Box display="flex" justifyContent="center">
                  <AdSpaceCard
                    key={ad.id}
                    images={[ad.attachments[0]?.file_url]}
                    title={ad.title}
                    priceConfig={priceConfig}
                    price={getAdSpacePriceFromConfig(ad, priceConfig)}
                    removeAdSpace={() => removeSelectedAdSpaces(ad)}
                    showAction={showAdSpaceCardAction}
                    showAdSpacePrice={ad.asset?.config?.show_adspace_price}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={1} p={1} className={classes.dividerSoft} />
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
          <Button variant="contained" color="primary" onClick={onClose}>
            <Trans>Ok</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default SelectedAdSpaceModal;
