/* eslint-disable no-nested-ternary */
import React from "react";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import TrashIcon from "../../icons/trash-icon";
import WarningIcon from "../../icons/warning-icon";
import theme from "../../theme";
import { formatCurrencyForTenant } from "../../utils/currencyUtils";
import { AdSpacePriceConfigTranslations } from "../../utils/spots";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    maxHeight: "230px",
    maxWidth: "348px",
    width: "348px",
    height: "81px",
    border: ({ isSelected, isAvailable }) =>
      !isAvailable
        ? `1px solid ${theme.palette.lighterRed}`
        : isSelected
        ? `1px solid ${theme.palette.primary.main}`
        : theme.borders[5],
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    backgroundColor: ({ isAvailable }) => (!isAvailable ? theme.palette.lighterRed : theme.palette.white),
  },
  cardDesc: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1 / 2),
    paddingRight: theme.spacing(0),
    alignItems: "flex-start",
    width: "100%",
  },
  titleText: {
    fontSize: "13px",
    fontWeight: 700,
    display: "inline-block",
    textOverflow: "ellipsis",
    maxHeight: 40,
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "185px",
  },
  imageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "80px",
    padding: "0",
    minWidth: "120px",
    width: "120px",
    transform: "translate(-0.5px, -0.5px)",
    "&:hover > div > img": {
      filter: "brightness(70%)",
      transition: "all 0.3s",
    },
  },
  visibilityIconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "120px",
    height: "79px",
    cursor: "pointer",
    "&:hover > svg": {
      opacity: 1,
    },
  },
  visibilityIcon: {
    position: "absolute",
    width: "48px",
    height: "48px",
    color: theme.palette.white,
    opacity: 0,
    transition: "all 0.3s",
    filter: "brightness(100%)",
    cursor: "pointer",
  },
  disabledText: {
    color: theme.palette.grayC4,
  },
  actionButtonsContainer: {
    paddingRight: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 2),
    backgroundColor: ({ isAvailable }) => (!isAvailable ? `${theme.palette.lighterRed}` : theme.palette.white),
  },
}));

function AdSpaceCard({
  images,
  title,
  price,
  priceConfig,
  showAdSpacePrice,
  removeAdSpace = () => {},
  isAvailable = true,
  showAction = true,
}) {
  const classes = useStyles({ ...theme, isAvailable });
  const { i18n } = useLingui();

  return (
    <Box className={classes.card}>
      <Box className={classes.imageBox}>
        <Image
          disableSpinner
          color={theme.palette.darkGray}
          src={images}
          style={{
            height: "100%",
            padding: "0",
            minWidth: "120px",
            width: "120px",
            cursor: "pointer",
          }}
          imageStyle={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box className={classes.cardDesc} textAlign="left">
        <Box color="text.primary">
          <Typography variant="body1" className={`${classes.titleText}`}>
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          height="100%"
        >
          {showAdSpacePrice && (
            <Box display="flex" flexDirection="row" color="text.primary">
              <Typography variant="subtitle1" color="primary" className={classes.text}>
                <Trans>Desde</Trans> {formatCurrencyForTenant(price)}/
                {i18n._(AdSpacePriceConfigTranslations[priceConfig])}
              </Typography>
            </Box>
          )}

          {showAction && (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              className={classes.actionButtonsContainer}
            >
              {!isAvailable && (
                <WarningIcon
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: theme.palette.lighterRed,
                    transform: "translateY(-2px)",
                  }}
                  stroke={theme.palette.error.main}
                />
              )}
              <Box style={{ cursor: "pointer" }} ml={1 / 2} onClick={removeAdSpace}>
                <TrashIcon />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AdSpaceCard;
