import { Box, Button, Container, Grid, Typography, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// icons
import { object } from "prop-types";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import theme from "../../theme";
// local components
import AdSpacesModalTenant from "../../containers/space-info-paper/ad-spaces-remove-modal-tenant";
import SpaceInfoPaper from "../space-info-paper";
import TextField from "../text-field";
import NotContact from "./not-contact";

const propTypes = {
  space: object,
  booking: object,
  user: object,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    padding: 0,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  root: {
    flexGrow: 1,
  },
  inputLabel: {
    marginTop: theme.spacing(2),
  },
  submit: {},
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dossierContainer: {
    display: "none",
    "& > div": {
      width: "100%",
    },
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  inputAttachCover: {
    display: "flex",
    position: "absolute",
    top: 40,
    alignItems: "center",
    width: "100%",
    "&> p": {
      opacity: 0.7,
      flex: 5,
      paddingLeft: theme.spacing(2),
    },
    "&> svg": {
      flex: 0.5,
      transform: "rotate(-45deg)",
    },
    "&> button": {
      height: 48,
    },
  },
  field: {
    marginBottom: 0,
  },
}));
const INITIAL_VALUES = {
  brandname: "",
  projectname: "",
  projectCategory: "",
  description: "",
};

const FirstContactComponent = ({ user = {}, onSubmit, status, booking, requestStatus }) => {
  const classes = useStyles(theme);

  const [values, setValues] = useState(INITIAL_VALUES);

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({ ...state, [field]: target.value }));
    };

  const handleSubmit = () => onSubmit(values);

  const { i18n } = useLingui();

  return user.isOwner ? (
    <NotContact />
  ) : (
    <>
      <Container className={classes.container}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={8}>
            <Box>
              <Typography
                variant="h2"
                style={{
                  color: theme.palette.text.primary,
                  textAlign: "center",
                }}
              >
                <Trans>EL PROPIETARIO QUIERE SABER DE TI</Trans>
              </Typography>
              <Typography variant="subtitle1" className={classes.description}>
                {`${i18n._(t`¡Hola`)} ${user.name}${i18n._(
                  t`! El propietario necesita saber un poco más sobre tu producto y tu evento.`,
                )}`}
              </Typography>
            </Box>
            <ValidatorForm onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1" color="textPrimary">
                  <Trans>¿Cuál es el nombre de la marca?*</Trans>
                </Typography>
                <TextField
                  inputContainerClassNames={`${classes.field}`}
                  placeholder={`${i18n._(t`Nombre de la marca`)}`}
                  value={values.brandname}
                  onChange={handleInputChange("brandname")}
                  validators={["required", "maxStringLength:30"]}
                  errorMessages={[`${i18n._(t`Campo requerido`)}`, `${i18n._(t`Nombre demasiado extenso`)}`]}
                  className={classes.inputs}
                  fullWidth
                />
                <Box mt={3 / 2}>
                  <Typography variant="subtitle1" color="textPrimary">
                    <Trans>¿Cuál es el nombre de tu producto o proyecto?*</Trans>
                  </Typography>
                </Box>
                <TextField
                  inputContainerClassNames={`${classes.field}`}
                  placeholder={`${i18n._(t`Nombre de tu producto o proyecto`)}`}
                  value={values.projectname}
                  onChange={handleInputChange("projectname")}
                  validators={["required", "maxStringLength:40"]}
                  errorMessages={[`${i18n._(t`Campo requerido`)}`, `${i18n._(t`Nombre demasiado extenso`)}`]}
                  className={classes.inputs}
                  fullWidth
                />
                <Box mt={3 / 2}>
                  <Typography variant="subtitle1" color="textPrimary">
                    <Trans>Cuenta en unas lineas lo que planeas hacer*</Trans>
                  </Typography>
                </Box>
                <TextField
                  inputContainerClassNames={`${classes.field}`}
                  multiline
                  rows={4}
                  validators={["minStringLength:1", "maxStringLength:500"]}
                  errorMessages={[
                    `${i18n._(t`Por favor describe tu proyecto`)}`,
                    `${i18n._(t`Máximo de 500 caracteres`)}`,
                  ]}
                  value={values.description}
                  className={classes.inputs}
                  onChange={handleInputChange("description")}
                  fullWidth
                />
              </Box>
              {status === "error" && (
                <Typography variant="body1" color="error">
                  {`${i18n._(t`Lo sentimos, hubo un error al procesar tu solicitud, vuelve a intentar por favor.`)}`}
                </Typography>
              )}
              <Box display="flex" flexDirection="row" justifyContent="center" mt={3}>
                <Button
                  disabled={status === "loading" || requestStatus === "loading"}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  <Trans>GUARDAR INFORMACIÓN</Trans>
                  {(status === "loading" || requestStatus === "loading") && "..."}
                </Button>
              </Box>
            </ValidatorForm>
          </Grid>
          <Hidden smDown>
            <Grid item md={4}>
              {/* TODO: IMPROVE THE SPACE INFO COMPONENT */}
              <SpaceInfoPaper booking={booking} AdSpaceModal={AdSpacesModalTenant} hideCancelRequestButton />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </>
  );
};

FirstContactComponent.propTypes = propTypes;

export default FirstContactComponent;
