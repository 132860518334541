import React from "react";
import FirstContactAdSpace from "/src/containers/first-contact/ad-space";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import SetupContainer from "/src/setup-container";

const ConsultAdSpace = () => {
  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Advertising`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <Router id="localized-router" basePath="/">
          <FirstContactAdSpace path="consult-adspace/:assetId" />
        </Router>
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default ConsultAdSpace;
